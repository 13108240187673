exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-template-js": () => import("./../../../src/pages/blog-template.js" /* webpackChunkName: "component---src-pages-blog-template-js" */),
  "component---src-pages-capabilities-digital-transformation-js": () => import("./../../../src/pages/capabilities/digital-transformation.js" /* webpackChunkName: "component---src-pages-capabilities-digital-transformation-js" */),
  "component---src-pages-capabilities-research-and-development-js": () => import("./../../../src/pages/capabilities/research-and-development.js" /* webpackChunkName: "component---src-pages-capabilities-research-and-development-js" */),
  "component---src-pages-capabilities-resource-augmentation-js": () => import("./../../../src/pages/capabilities/resource-augmentation.js" /* webpackChunkName: "component---src-pages-capabilities-resource-augmentation-js" */),
  "component---src-pages-capabilities-template-js": () => import("./../../../src/pages/capabilities-template.js" /* webpackChunkName: "component---src-pages-capabilities-template-js" */),
  "component---src-pages-capabilities-transformation-advisory-js": () => import("./../../../src/pages/capabilities/transformation-advisory.js" /* webpackChunkName: "component---src-pages-capabilities-transformation-advisory-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-application-js": () => import("./../../../src/pages/job-application.js" /* webpackChunkName: "component---src-pages-job-application-js" */),
  "component---src-pages-job-description-js": () => import("./../../../src/pages/job-description.js" /* webpackChunkName: "component---src-pages-job-description-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-security-policy-js": () => import("./../../../src/pages/security-policy.js" /* webpackChunkName: "component---src-pages-security-policy-js" */),
  "component---src-pages-talent-outsource-js": () => import("./../../../src/pages/talent-outsource.js" /* webpackChunkName: "component---src-pages-talent-outsource-js" */),
  "component---src-pages-what-they-say-js": () => import("./../../../src/pages/what-they-say.js" /* webpackChunkName: "component---src-pages-what-they-say-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-what-we-think-js": () => import("./../../../src/pages/what-we-think.js" /* webpackChunkName: "component---src-pages-what-we-think-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-capabilities-template-js": () => import("./../../../src/templates/capabilities-template.js" /* webpackChunkName: "component---src-templates-capabilities-template-js" */),
  "component---src-templates-industries-template-js": () => import("./../../../src/templates/industries-template.js" /* webpackChunkName: "component---src-templates-industries-template-js" */),
  "component---src-templates-job-application-template-js": () => import("./../../../src/templates/job-application-template.js" /* webpackChunkName: "component---src-templates-job-application-template-js" */),
  "component---src-templates-job-description-template-js": () => import("./../../../src/templates/job-description-template.js" /* webpackChunkName: "component---src-templates-job-description-template-js" */)
}

